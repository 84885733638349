import React from 'react';
import ReactDOM from 'react-dom';
import { browserHistory, Route, Router } from 'react-router';

import { QueryClient, QueryClientProvider } from 'react-query';
import { UdfManager } from './components/UdfManager';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  }
});

export interface RouterProps {
  params: {
    [uid: string]: string;
  };
}

const WrapUdfManagerForReactRouter = (props: RouterProps) => (
  <UdfManager uid={props.params.uid} />
);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router history={browserHistory}>
      <Route path="admin/udf" component={WrapUdfManagerForReactRouter}>
        <Route path=":uid" component={WrapUdfManagerForReactRouter}/>
      </Route>
    </Router>
  </QueryClientProvider>,
  document.querySelector('#udf-main')
);
