import { Option, some, none } from 'ts-option';
import I18n from 'common/i18n';
import { isCoreException, isCollocationJobRejected, CollocateJobFailed } from 'common/core/collocation';
import { Tab } from 'common/explore_grid/types';
import { downcastOption } from '../lib/selectors';

export const translateRemoteStatus = (key: string, options: { [key: string]: any } = {}) => I18n.t(key, { scope: 'shared.explore_grid.remote_status', ...options });

export enum RemoteStatus {
  CannotCompileQuery = 'cannot-compile-query',
  CannotRunQuery = 'cannot-run-query',
  CompiledQueryNeedsCollocation = 'compiled-query-needs-collocation',
  CanRunCompiledQuery = 'can-run-compiled-query',
  CompilingCandidateQuery = 'compiling-candidate-query',
  CollocatingCompiledQuery = 'collocating-compiled-query',
  RunningCompiledQuery = 'running-compiled-query',
  QueryRanSuccessfully = 'query-ran-successfully',
  RunnableParameterChanges = 'runnable-parameter-changes'
}

interface CannotCompileQuery {
  type: RemoteStatus.CannotCompileQuery;
  reason: string;
}

interface CannotRunQuery {
  type: RemoteStatus.CannotRunQuery;
  requestId: Option<string>;
  reason: string;
}

interface CompiledQueryNeedsCollocation {
  type: RemoteStatus.CompiledQueryNeedsCollocation;
}

interface CanRunCompiledQuery {
  type: RemoteStatus.CanRunCompiledQuery;
}

interface CompilingCandidateQuery {
  type: RemoteStatus.CompilingCandidateQuery;
}

interface CollocatingCompiledQuery {
  type: RemoteStatus.CollocatingCompiledQuery;
}

interface RunningCompiledQuery {
  type: RemoteStatus.RunningCompiledQuery;
  successTab: Option<Tab>;
}

interface QueryRanSuccessfully {
  type: RemoteStatus.QueryRanSuccessfully;
  successTab: Option<Tab>;
}

interface RunnableParameterChanges {
  type: RemoteStatus.RunnableParameterChanges
}

export const builders = {
  cannotCompileQueryBecause: (reason: string): CannotCompileQuery => ({
    type: RemoteStatus.CannotCompileQuery,
    reason
  }),
  cannotRunQuery: (reason: string): CannotRunQuery => ({
    type: RemoteStatus.CannotRunQuery,
    requestId: none,
    reason
  }),
  cannotRunQueryBecauseCollocation: (response: CollocateJobFailed): CannotRunQuery => ({
    type: RemoteStatus.CannotRunQuery,
    requestId: some(response.requestId),
    reason: (() => {
      if (isCoreException(response)) {
        if (response.code === 'permission-denied') return 'collocation_not_permitted';
        else if (response.code === 'not-found') return 'collocating_nonexistent_view'; // can happen from code editor
        // else: InvalidRequest, and we shouldn't make those.
      } else if (isCollocationJobRejected(response)) {
        // DC reasons to reject collocation.
        //if (response.message.includes('the number of moves exceed the limit')) {
        //} else if (response.message.includes('the total size in bytes exceeds the limit')) {
        //} else if (response.message.includes('the max move size in bytes exceeds the limit')) {
        //} else if (response.message.includes('the moves exceed the capacity of store')) {
        //}
        return 'join_operation_too_large';
      }
      return 'generic_collocation_error';
    })()
  }),
  compiledQueryNeedsCollocation: (): CompiledQueryNeedsCollocation => ({ type: RemoteStatus.CompiledQueryNeedsCollocation }),
  canRunCompiledQuery: (): CanRunCompiledQuery => ({ type: RemoteStatus.CanRunCompiledQuery }),
  compilingCandidateQuery: (): CompilingCandidateQuery => ({
    type: RemoteStatus.CompilingCandidateQuery
  }),
  collocatingCompiledQuery: (): CollocatingCompiledQuery => ({
    type: RemoteStatus.CollocatingCompiledQuery
  }),
  runningCompiledQuery: (successTab: Option<Tab> = none): RunningCompiledQuery => ({
    type: RemoteStatus.RunningCompiledQuery,
    successTab
  }),
  queryRanSuccessfully: (successTab: Option<Tab>): QueryRanSuccessfully => ({
    type: RemoteStatus.QueryRanSuccessfully,
    successTab
  }),
  runnableParameterChanges: (): RunnableParameterChanges => ({ type: RemoteStatus.RunnableParameterChanges })
};

const selectors = {
  cannotCompileQuery: downcastOption<RemoteStatusInfo, CannotCompileQuery>(RemoteStatus.CannotCompileQuery),
  cannotRunQuery: downcastOption<RemoteStatusInfo, CannotRunQuery>(RemoteStatus.CannotRunQuery),
  compiledQueryNeedsCollocation: downcastOption<RemoteStatusInfo, CompiledQueryNeedsCollocation>(RemoteStatus.CompiledQueryNeedsCollocation),
  canRunCompiledQuery: downcastOption<RemoteStatusInfo, CanRunCompiledQuery>(RemoteStatus.CanRunCompiledQuery),
  compilingCandidateQuery: downcastOption<RemoteStatusInfo, CompilingCandidateQuery>(RemoteStatus.CompilingCandidateQuery),
  collocatingCompiledQuery: downcastOption<RemoteStatusInfo, CollocatingCompiledQuery>(RemoteStatus.CollocatingCompiledQuery),
  runningCompiledQuery: downcastOption<RemoteStatusInfo, RunningCompiledQuery>(RemoteStatus.RunningCompiledQuery),
  queryRanSuccessfully: downcastOption<RemoteStatusInfo, QueryRanSuccessfully>(RemoteStatus.QueryRanSuccessfully),
  runnableParameterChanges: downcastOption<RemoteStatusInfo, RunnableParameterChanges>(RemoteStatus.RunnableParameterChanges)
};

type DiscardChangesOnTabChange = CannotCompileQuery | CannotRunQuery;
type InProgress = CompilingCandidateQuery | CollocatingCompiledQuery | RunningCompiledQuery;
const moreSelectors = { ...selectors,
  discardChangesOnTabChange: (maybe: Option<RemoteStatusInfo>): Option<DiscardChangesOnTabChange> => {
    let result;
    result = selectors.cannotCompileQuery(maybe);
    if (result.isDefined) return result;
    result = selectors.cannotRunQuery(maybe);
    if (result.isDefined) return result;
    return none;
  },
  inProgress: (maybe: Option<RemoteStatusInfo>): Option<InProgress> => {
    let result;
    result = selectors.compilingCandidateQuery(maybe);
    if (result.isDefined) return result;
    result = selectors.collocatingCompiledQuery(maybe);
    if (result.isDefined) return result;
    result = selectors.runningCompiledQuery(maybe);
    if (result.isDefined) return result;
    return none;
  },
  applyable: (maybe: Option<RemoteStatusInfo>): Option<CanRunCompiledQuery | CompiledQueryNeedsCollocation | RunnableParameterChanges> => {
    let result;
    result = selectors.canRunCompiledQuery(maybe);
    if (result.isDefined) return result;
    result = selectors.compiledQueryNeedsCollocation(maybe);
    if (result.isDefined) return result;
    result = selectors.runnableParameterChanges(maybe);
    if (result.isDefined) return result;
    return none;
  }
};

export { moreSelectors as selectors };

export type RemoteStatusInfo =
  CannotCompileQuery |
  CannotRunQuery |
  CompiledQueryNeedsCollocation |
  CanRunCompiledQuery |
  CompilingCandidateQuery |
  CollocatingCompiledQuery |
  RunningCompiledQuery |
  QueryRanSuccessfully |
  RunnableParameterChanges;
