import { Option } from 'ts-option';
import { checkStatus, fetchWithDefaultHeaders, fetchJsonWithParsedError } from 'common/http';
import { View, UdfDefinition, PublicationStage } from 'common/types/view';
import { Scope } from 'common/types/soql';
import { sortBy } from 'lodash';

export const getParameterizedViews = async (): Promise<View[]> => {
  return await fetchWithDefaultHeaders('/api/parameterized_views')
    .then(checkStatus)
    .then(async (response: Response) => {
      const views = await response.json() as View[];
      return sortBy(views, (v) => v.name.toLowerCase());
    });
};

export const getScope = async (): Promise<Scope> => {
  return fetchWithDefaultHeaders('/api/publishing/v1/soql_info')
    .then(checkStatus)
    .then(async (response: Response) => {
      const { functions } =  await response.json();
      return functions;
    });
};

export const saveUdf = async (
    view: Option<View>,
    udfName: string,
    resourceName: Option<string>,
    udfDefinition: UdfDefinition
  ): Promise<View> => {
  const toSave = {
    name: udfName,
    udfDefinition: udfDefinition,
    displayType: 'parameterized_view',
    ...resourceName.nonEmpty && { resourceName: resourceName.get }
  };
  const path = view.isEmpty ? '/api/views' : `/api/views/${view.get.id}`;
  const method = view.isEmpty ? 'POST' : 'PUT';
  return fetchJsonWithParsedError(
    path,
    { method: method, body: JSON.stringify(toSave) }
  ).then((response: View) => {
    if (response.publicationStage !== PublicationStage.Published) {
      return fetchJsonWithParsedError(
        `/api/views/${response.id}/publication`,
        { method: 'POST', body: ''}
      );
    } else {
      return Promise.resolve(response);
    }
  });
};

export const deleteUdf = async (view: View): Promise<any> => {
  return fetchJsonWithParsedError(
    `/api/views/${view.id}`,
    { method: 'DELETE' }
  );
};

export const validateUdf = async (
    view: Option<View>,
    udfName: string,
    resourceName: Option<string>,
    udfDefinition: UdfDefinition
  ): Promise<View> => {
    const toValidate = {
      name: udfName,
      udfDefinition: udfDefinition,
      ...resourceName.nonEmpty && { resourceName: resourceName.get },
      ...view.nonEmpty && { viewUid: view.get.id }
    };
    return fetchJsonWithParsedError(
      '/api/parameterized_views?method=validate',
      { method: 'PUT', body: JSON.stringify(toValidate) }
    );
  };
