import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { none, some, Option } from 'ts-option';
import { browserHistory } from 'react-router';

import I18n from 'common/i18n';
import { View } from 'common/types/view';
import { Scope } from 'common/types/soql';
import { UdfEditor } from './UdfEditor';
import { getScope, getParameterizedViews } from '../lib/udf_manager_api';
import {
  ForgeButton,
  ForgeCircularProgress,
  ForgeDrawer,
  ForgeIcon,
  ForgeIconButton,
  ForgeList,
  ForgeListItem,
  ForgeScaffold,
  ForgeTextField,
  ForgeToast,
} from '@tylertech/forge-react';

const t = (k: string) => I18n.t(k, { scope: 'screens.admin.udf_manager' });

interface ToastInfo {
  open: boolean;
  error: boolean;
  message: string;
}

interface Props {
  uid?: string;
}

export const UdfManager: FC<Props> = ({ uid }) => {
  const noToast = { open: false, error: false, message: '' };
  const { data: views, isLoading, isError, refetch } = useQuery<View[]>('get-udf-views', getParameterizedViews);
  const [ selectedView, setSelectedView ] = useState<Option<View>>(none);
  const [ toastInfo, setToastInfo ] = useState<ToastInfo>(noToast);
  const [ scope, setScope ] = useState<Scope | undefined>(undefined);
  const [ searchValue, setSearchValue ] = useState<string>('');

  useEffect(() => {
    getScope().then((returnedScope) => {
      setScope(returnedScope);
    });
  }, []);

  useEffect(() => {
    if (views) {
      // when a new view is created, it will be selected, but not yet in 'views' until after we refresh
      const allViews = selectedView.isEmpty ? views : [...views, selectedView.get];
      const openView = allViews.find(v => v.id === uid);
      if (openView) {
        setSelectedView(some(openView));
      } else {
        setSelectedView(none);
        browserHistory.push('/admin/udf');
      }
    }
  }, [uid, views]);

  const clearToast = () => setToastInfo(noToast);
  const toastIcon = toastInfo.error ? <ForgeIcon name='error' className='toast-error-icon'/> : <ForgeIcon name='check' className='toast-success-icon'/>;
  const toastOptions = {
    placement: 'top',
    message: toastInfo.message,
    id: 'udf-manager-toast',
    ...toastInfo.error && {duration: Infinity}
  };
  const toast = (
    <ForgeToast
      open={toastInfo.open}
      onDismiss={clearToast}
      options={toastOptions}
    >
      <span className='toast-icon'>{toastIcon}</span>
    </ForgeToast>
  );

  const makeToast = (message: string, error: boolean) => {
    setToastInfo({ open: true, error, message });
  };

  if (isError) {
    return <span>error</span>;
  }

  if (!views || !scope || isLoading) {
    return <ForgeCircularProgress
      open={open}
      progressbar-aria-label="Loading"
    />;
  }

  const setUrl = (v: Option<View>) => {
    if (v.isEmpty) {
      browserHistory.push('/admin/udf');
    } else {
      browserHistory.push(`/admin/udf/${v.get.id}`);
    }
  };

  const onClick = (v: Option<View>) => {
    setUrl(v);
    setSelectedView(v);
    if (v.isEmpty) {
      clearSearch();
    }
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleSearchChange = (event: React.FormEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;
    setSearchValue(searchText);
  };

  return (
    <>
      <ForgeScaffold className="udf-scaffold">
        <ForgeDrawer slot='left' open={true} direction='left'>
          <span className="udf-list-title">
            <h3 className="forge-typography--title">{t('saved')}</h3>
            <ForgeButton type='outlined'>
              <button onClick={() => onClick(none)}>
                <ForgeIcon name="add"/>
                <span className="udf-list-button">{t('create')}</span>
              </button>
            </ForgeButton>
            <ForgeTextField
              aria-label={t('search_udfs')}
              className='udf-search'
              density='default'
            >
              <ForgeIcon slot="leading" name="search"/>
              <input
                autoComplete="off"
                type="text"
                onChange={handleSearchChange}
                value={searchValue}
              />
              <ForgeIconButton slot="trailing">
                <button type="button"
                  onClick={clearSearch}
                  aria-label={I18n.t('shared.components.searchbar.clear')}>
                  <ForgeIcon name="clear"/>
                </button>
              </ForgeIconButton>
            </ForgeTextField>
          </span>
            <ForgeList className="udf-list">
              {views
                .filter((v) => v.name.toLowerCase().includes(searchValue.toLowerCase()))
                .map((v) => (
                  <ForgeListItem
                    data-testid="udf-list-item"
                    selected={selectedView.nonEmpty && selectedView.get.id === v.id}
                    key={v.id}
                    onClick={() => { onClick(some(v)); }}
                  >
                    {v.name}
                  </ForgeListItem>
                ))}
            </ForgeList>
        </ForgeDrawer>
        <UdfEditor
          makeToast={makeToast}
          view={selectedView}
          triggerRefetch={refetch}
          scope={scope}
          setSelectedView={setSelectedView}
          setUrl={setUrl}
        />
      </ForgeScaffold>
      {toast}
    </>
  );
};
