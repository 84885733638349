import React, { FC } from 'react';
import { ForgeIconButton, ForgeButton, ForgeIcon, ForgeList, ForgeListItem } from '@tylertech/forge-react';
import { UdfParameter } from 'common/types/view';
import I18n from 'common/i18n';
import ColumnTypeIcon from 'common/components/ColumnTypeIcon';

const t = (k: string) => I18n.t(k, { scope: 'screens.admin.udf_manager.parameters' });

interface ParameterListProps {
  parameters: UdfParameter[];
  openCreateDialog: () => void;
  openEditDialog: (p: UdfParameter) => void;
}

export const ParameterList: FC<ParameterListProps> = ({parameters, openCreateDialog, openEditDialog }) => {
  return (
    <>
      <span className="udf-list-title">
        <h3 className="forge-typography--title">{t('parameters')}</h3>
        <ForgeButton type='outlined' className="udf-list-button">
          <button onClick={openCreateDialog}>
            <ForgeIcon name="add"/>
            <span>{t('create_parameter')}</span>
          </button>
        </ForgeButton>
      </span>
      <ForgeList className="udf-list">
        {parameters.map(param =>
          <ForgeListItem data-testid="parameter-list-item" key={param.name}>
            <ColumnTypeIcon slot="leading" type={param.type} forge={true} />
            <span>{param.name}</span>
            <ForgeIconButton slot="trailing" type="raised">
              <button onClick={() => openEditDialog(param)}>
                <ForgeIcon name="edit"/>
              </button>
            </ForgeIconButton>
          </ForgeListItem>
        )}
      </ForgeList>
    </>
  );
};
