import { SoQLType } from 'common/types/soql';
import React, { FC, useState } from 'react';

import {
  ForgeButton,
  ForgeDialog,
  ForgeOption,
  ForgeSelect,
  ForgeTextField,
  ForgeToolbar
} from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { UdfParameter } from 'common/types/view';
import { Option } from 'ts-option';
const t = (k: string) => I18n.t(k, { scope: 'screens.admin.udf_manager' });

interface ParameterDialogProps {
  parameters: UdfParameter[];
  parameterToEdit: Option<UdfParameter>;
  closeDialog: () => void;
  setParameters: (p: UdfParameter[]) => void;
  deleteParameter: (p: UdfParameter) => void;
}

export const ParameterDialog: FC<ParameterDialogProps> = ({ parameters, parameterToEdit, closeDialog, setParameters, deleteParameter }) => {

  const editing = parameterToEdit.isDefined;

  const [ formDirty, setFormDirty ] = useState<boolean>(false);
  const [ name, setName ] = useState<string>(parameterToEdit.map(p => p.name).getOrElseValue(''));
  const [ nameValidityError, setNameValidityError ] = useState<boolean>(false);
  const [ description, setDescription ] = useState<string>(parameterToEdit.map(p => p.description || '').getOrElseValue(''));
  const [ type, setType ] = useState<SoQLType>(parameterToEdit.map(p => p.type).getOrElseValue(SoQLType.SoQLTextT));

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const namesToCheck = editing ? parameters.filter(p => p.name != parameterToEdit.get.name) : parameters;
    const validityError = namesToCheck.filter((p) => p.name === newName).length > 0 || (!/^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(newName));
    setFormDirty(true);
    setNameValidityError(newName.length < 1 || validityError);
    setName(newName);
  };
  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
    setFormDirty(true);
  };
  const onTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value as SoQLType);
    setFormDirty(true);
  };

  const saveParameters = (ps: UdfParameter[]) => {
    const parameterToAdd = {
      name,
      type,
      ...!!description && { description }
    };

    setParameters([...ps, parameterToAdd]);

    closeDialog();
  };

  const editParameter = () => {

    const filteredParameters = parameters.filter(p => {
      return parameterToEdit.match({
        some: pte => pte.name !== p.name,
        none: () => true
      });
    });

    saveParameters(filteredParameters);
  };

  const createParameter = () => saveParameters(parameters);

  return (
    <ForgeDialog open={true} onDismiss={closeDialog}>
      <div className="udf-parameters-dialog">
        <header>
          <h1 slot="start" className="forge-typography--title">{editing ? t('edit_parameter') : t('create_parameter')}</h1>
        </header>
        <section className="forge-dialog__body">
          <div className="forge-dialog-body__padding">
            <div className='udf-parameters-dialog-top-panel'>
              <ForgeTextField>
                <input value={name} type="text" id="input-text-API-field-name" onChange={onNameChange}/>
                <label htmlFor="input-text-API-field-name" slot="label">{t('name')}</label>
                {nameValidityError ? <span className="helper-text" slot="helper-text">{t('name_error')}</span> : null}
              </ForgeTextField>
              <ForgeSelect id="input-datatype-select" label={'Type'} value={type}
                popupClasses={'datatype-popup'}
                onChange={onTypeChange}>
                  <ForgeOption value="text">{t('text')}</ForgeOption>
                  <ForgeOption value="number">{t('number')}</ForgeOption>
                  <ForgeOption value="calendar_date">{t('calendar_date')}</ForgeOption>
                  <ForgeOption value="checkbox">{t('checkbox')}</ForgeOption>
              </ForgeSelect>
            </div>
            <div className='udf-parameters-dialog-bottom-panel'>
              <ForgeTextField>
                <input value={description} type="text" id="input-text-API-field-name" onChange={onDescriptionChange}/>
                <label htmlFor="input-text-API-field-name" slot="label">{t('description')}</label>
              </ForgeTextField>
            </div>
          </div>
        </section>
        <footer>
          <ForgeToolbar inverted={true} className="footer-toolbar">
            {editing && <div slot="start">
              <ForgeButton type="outlined">
                <button id="delete-button" onClick={() => deleteParameter(parameterToEdit.get)}>{t('delete')}</button>
              </ForgeButton>
            </div>}
            <div slot="end">
              <ForgeButton type="outlined">
                <button id="cancel-button" onClick={closeDialog}>{t('cancel')}</button>
              </ForgeButton>
              <ForgeButton type="raised">
                <button
                 id="accept-button"
                 onClick={editing ? editParameter : createParameter }
                 disabled={!formDirty || nameValidityError}>
                   {t('save')}
                </button>
              </ForgeButton>
            </div>
          </ForgeToolbar>
        </footer>
      </div>
    </ForgeDialog>
  );
};
