
import React, { FC, useEffect, useState } from 'react';
import { none, some, option, Option } from 'ts-option';
import { sortBy } from 'lodash';

import { View, UdfDefinition, UdfParameter } from 'common/types/view';
import { ForgeButton, ForgeDrawer, ForgeIcon, ForgeIconButton, ForgeMenu, ForgeTextField, ForgeToolbar } from '@tylertech/forge-react';
import { IMenuSelectEventData } from '@tylertech/forge';
import SoQLEditor from 'common/components/SoQLEditor';
import { Scope } from 'common/types/soql';
import I18n from 'common/i18n';

import { renderColumn, renderFunction } from 'common/explore_grid/components/SoQLQueryDocs';

import SoQLDocs, { defaultMatcher, Match } from 'common/components/SoQLDocs';
import { ParameterList } from './ParameterList';
import { saveUdf, deleteUdf, validateUdf } from '../lib/udf_manager_api';
import { ParameterDialog } from './ParameterDialog';

const t = (k: string) => I18n.t(k, { scope: 'screens.admin.udf_manager' });

interface UdfEditorProps {
  view: Option<View>;
  scope: Scope;
  makeToast: (msg: string, error: boolean) => void;
  triggerRefetch: () => void
  setSelectedView: (view: Option<View>) => void
  setUrl: (view: Option<View>) => void
}

export const UdfEditor: FC<UdfEditorProps> = ({ view: providedView, makeToast, triggerRefetch, scope, setSelectedView, setUrl }) => {
  const [ view, setView ] = useState<Option<View>>(providedView);
  const [ needsResize, setNeedsResize ] = useState<boolean>(true);
  const [ selectedFunction, setSelectedFunction ] = useState<Option<Match>>(none);
  const [ udfName, setUdfName ] = useState<string>(providedView.map(v => v.name).getOrElseValue(''));
  const [ udfDefinition , setUdfDefinition ] = useState<UdfDefinition>(
    providedView.flatMap(v => option(v.udfDefinition)).getOrElseValue({soql: '', parameters: []})
  );
  const [ resourceName, setResourceName ] = useState<Option<string>>(providedView.flatMap(v => option(v.resourceName)));
  const [ parameterDialogOpen, setParameterDialogOpen ] = useState<boolean>(false);
  const [ parameterToEdit, setParameterToEdit ] = useState<Option<UdfParameter>>(none);

  useEffect(() => {
    setView(providedView);
  }, [providedView]);

  useEffect(() => {
    setResourceName(view.flatMap(v => option(v.resourceName)));
    setUdfName(view.map(v => v.name).getOrElseValue(''));
    setUdfDefinition(view.flatMap(v => option(v.udfDefinition)).getOrElseValue({soql: '', parameters: []}));
  }, [view]);

  const setViewAndSetSelectedView = (toSet: Option<View>) => {
    setView(toSet);
    setSelectedView(toSet);
    setUrl(toSet);
  };

  const openEditParameterDialog = (parameter: UdfParameter) => {
    setParameterToEdit(some(parameter));
    setParameterDialogOpen(true);
  };

  const openCreateParameterDialog = () => {
    setParameterToEdit(none);
    setParameterDialogOpen(true);
  };

  const matcher = defaultMatcher(
    scope,
    [],
    renderFunction,
    renderColumn,
    true
  );

  const onSave = () => {
    saveUdf(view, udfName, resourceName, udfDefinition).then((response: View) => {
      setViewAndSetSelectedView(some(response));
      triggerRefetch();
      makeToast(t('toasts.save_success'), false);
    }).catch((err: any) => {
        console.error('Failed to save: ', err);
        let message = t('toasts.save_fail');
        if (typeof err.response?.headers?.get === 'function' && err.response.headers.get('x-error-message')) {
            message = err.response.headers.get('x-error-message');
        }
        makeToast(message, true);
    });
  };

  const onDelete = () => {
    if (view.isEmpty) return;
    deleteUdf(view.get).then(() => {
      setViewAndSetSelectedView(none);
      triggerRefetch();
      makeToast(t('toasts.delete_success'), false);
    }).catch((err: any) => {
      console.error('Unable to delete UDF: ', err);
      makeToast(t('toasts.delete_fail'), true);
    });
  };

  const onValidate = () => {
    validateUdf(view, udfName, resourceName, udfDefinition).then(() => {
      makeToast(t('toasts.valid'), false);
    }).catch((err: any) => {
      let message = t('toasts.invalid');
      if (typeof err.response?.headers?.get === 'function' && err.response.headers.get('x-error-message')) {
          message = err.response.headers.get('x-error-message');
      }
      makeToast(message, true);
    });
  };

  const setParameters = (parameters: UdfParameter[]) => {
    setUdfDefinition({
      ...udfDefinition,
      parameters
    });
  };

  const deleteParameter = (parameter: UdfParameter) => {
    setUdfDefinition({
      ...udfDefinition,
      parameters: udfDefinition.parameters.filter(p => p.name !== parameter.name)
    });
    setParameterDialogOpen(false);
  };

  const nameField = (
    <ForgeTextField
      floatLabel={t('udf_name')}
      floatLabelType="always"
      class="udf-text-field"
      hasLabel={true}
    >
      <input
        type="text"
        id="udf-name"
        value={udfName}
        onChange={(e) => setUdfName(e.target.value)}
        autoComplete="off"
      />
      <label htmlFor="udf-name" slot="label">{t('udf_name')}</label>
    </ForgeTextField>
  );

  const resourceNameField = () => {
    return (
      <ForgeTextField
        floatLabel={t('resource_name')}
        floatLabelType="always"
        hasLabel={true}
        class="udf-text-field"
      >
        <input
          type="text"
          id="resource-name"
          value={resourceName.getOrElseValue(view.nonEmpty ? view.get.id : '')}
          disabled={view.nonEmpty} // prevent people from probably shooting themselves in the foot
          onChange={(e) => {
            const rn = e.target.value === '' ? none : some(e.target.value);
            setResourceName(rn);
          }}
          autoComplete="off"
        />
        <label htmlFor="resource-name" slot="label">{t('resource_name')}</label>
      </ForgeTextField>
    );
  };

  return (
    <>
      <ForgeToolbar slot="header">
        <div slot="start">
          <h2 className="forge-typography--title">{view.map(v => v.name).getOrElseValue('Create New UDF')}</h2>
        </div>
        <div className="save-tool-end" slot="end">
          <ForgeButton type="raised">
            <button onClick={onSave}>
              {t('save')}
            </button>
          </ForgeButton>
          <ForgeMenu
            options={[{ value: 'delete', label: t('delete'), disabled: view.isEmpty}]}
            on-forge-menu-select={
              ({ detail }: CustomEvent<IMenuSelectEventData>) => {
                if (detail.value == 'delete') onDelete();
              }
            }
          >
            <ForgeIconButton type="raised">
              <button type="button">
                <ForgeIcon name="dots_vertical"/>
              </button>
            </ForgeIconButton>
          </ForgeMenu>
        </div>
      </ForgeToolbar>
      <section className="udf-editor-body" slot="body">
        <div slot="body-header">
          <span slot="start">{nameField}</span>
          <span slot="center">{resourceNameField()}</span>
        </div>
        <div className="text-editor-button-row">
          <ForgeButton class="udf-validate" slot="end" type="raised">
            <button onClick={onValidate}>
              <ForgeIcon name="play_arrow"/>
              {t('validate')}
            </button>
          </ForgeButton>
        </div>
        <div className="udf-text-editor">
          <SoQLEditor
            scope={scope}
            compilationResult={none}
            soql={udfDefinition.soql}
            matcher={matcher}
            selectedFunction={selectedFunction}
            onChange={(newSoql: string) => {
              setUdfDefinition({
                ...udfDefinition,
                soql: newSoql
              });
            }}
            onChangeSelectedFunction={setSelectedFunction}
            height={'500px'}
            soqlMode={true}
            needsResize={needsResize}
            onResizeComplete={() => setNeedsResize(false)}
          />
        </div>
        <div className="udf-soql-docs">
          <h4 className="udf-title forge-typography--title">{t('soql_doc')}</h4>
          <SoQLDocs
            selection={selectedFunction.isEmpty ? undefined : selectedFunction.get}
            completer={matcher} />
        </div>
      </section>
      <ForgeDrawer slot='body-right' direction='right'>
        <ParameterList
           openCreateDialog={openCreateParameterDialog}
           openEditDialog={openEditParameterDialog}
           parameters={udfDefinition.parameters}/>
      </ForgeDrawer>
      { parameterDialogOpen &&
        <ParameterDialog
           parameters={udfDefinition.parameters}
           parameterToEdit={parameterToEdit}
           setParameters={setParameters}
           deleteParameter={deleteParameter}
           closeDialog={() => setParameterDialogOpen(false)} />
        }
    </>
  );
};
