import I18n from 'common/i18n';
import { showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import { BinaryTree, Scope, UnAnalyzedAst } from 'common/types/soql';
import { View } from 'common/types/view';
import { none, Option } from 'ts-option';
import { Tab } from 'common/explore_grid/types';
import { getUnAnalyzedAst, querySuccess, replaceLastInChain } from '../lib/selectors';
import * as Actions from '../redux/actions';
import { Dispatcher } from '../redux/actions';
import { AppState, Query } from '../redux/store';
import { RemoteStatusInfo, selectors as SelectRemoteStatus } from '../redux/statuses';
import { ClientContextVariable } from 'common/types/clientContextVariable';

// got tired of writing the same redux boilerplate over and over for every
// component that can update the AST and run a query (lots of them)
// so these helpers get us from the app state to a set of props
// that the components can take

export type StageAST = (ast: UnAnalyzedAst, reason: string) => void;
export type CompileAST = (ast: UnAnalyzedAst, prepareResetPage: boolean) => void;
export type RunAST = (ast: UnAnalyzedAst, newPage: Option<number>) => void;
export type ApplyChanges = (tab: Option<Tab>) => void;
export type DiscardChanges = () => void;
export interface VisualContainerStateProps {
  fourfour: string;
  query: Query;
  scope: Scope;
  ast: Option<BinaryTree<UnAnalyzedAst>>;
  view: View;
  undocked: boolean;
  remoteStatusInfo: Option<RemoteStatusInfo>;
  parameters: ClientContextVariable[];
}
export interface VisualContainerDispatchProps {
  stageAST: (chain: Option<BinaryTree<UnAnalyzedAst>>) => StageAST;
  compileAST: (chain: Option<BinaryTree<UnAnalyzedAst>>) => CompileAST;
  runAST: (fourfour: string, chain: Option<BinaryTree<UnAnalyzedAst>>) => RunAST;
  updateTab: (remoteStatusInfo: Option<RemoteStatusInfo>, undocked: boolean) => (tab: Tab) => void;
  applyChanges: (fourfour: string, maybeChain: Option<BinaryTree<UnAnalyzedAst>>) => ApplyChanges;
  discardChanges: (lastQueryText: Option<string>) => DiscardChanges;
}
export interface ExternalProps {
}
export type VisualContainerProps = VisualContainerStateProps & ExternalProps & {
  stageAST: StageAST;
  runAST: RunAST;
  compileAST: CompileAST;
  updateTab: (tab: Tab) => void;
  applyChanges: (tab: Option<Tab>) => void;
  discardChanges: () => void;
};

export const mapStateToProps = (state: AppState, props: ExternalProps): VisualContainerStateProps => {
  return {
    ...props,
    query: state.query,
    fourfour: state.fourfourToQuery,
    scope: state.scope.getOrElseValue([]),
    ast: getUnAnalyzedAst(state.query),
    view: state.view,
    undocked: state.undocked,
    remoteStatusInfo: state.remoteStatusInfo,
    parameters: state.clientContextInfo.variables
  };
};
export const mapDispatchToProps = (dispatch: Dispatcher): VisualContainerDispatchProps => {
  return {
    stageAST: (maybeChain: Option<BinaryTree<UnAnalyzedAst>>) => (ast: UnAnalyzedAst, reason: string) => {
      // TODO: Not sure if we need maybeChain here.
      maybeChain.forEach(chain => {
        const newChain = replaceLastInChain(chain, ast);
        dispatch(Actions.stageAst(newChain, reason));
      });
    },
    compileAST: (maybeChain: Option<BinaryTree<UnAnalyzedAst>>) => (ast: UnAnalyzedAst, prepareResetPage: boolean) => {
      maybeChain.forEach(chain => {
        // if we're resetting page, that includes an offset added via soql editor
        const offsetModAst = prepareResetPage ? {...ast, offset: null} : ast;
        const newChain = replaceLastInChain(chain, offsetModAst);
        dispatch(Actions.compileAst(newChain, prepareResetPage));
      });
    },
    runAST: (fourfour: string, maybeChain: Option<BinaryTree<UnAnalyzedAst>>) => (ast: UnAnalyzedAst, newPage: Option<number>) => {
      maybeChain.forEach(chain => {
        const offsetModAst = newPage.nonEmpty ? {...ast, offset: null} : ast;
        const newChain = replaceLastInChain(chain, offsetModAst);
        dispatch(Actions.compileAndRunAst(fourfour, newChain, newPage));
      });
    },
    updateTab: (remoteStatusInfo: Option<RemoteStatusInfo>, undocked: boolean) => (tab: Tab) => {
      if (undocked && tab !== Tab.Code) { // Any time we try to switch tabs while undocked, redock before switching tabs.
        SelectRemoteStatus.queryRanSuccessfully(remoteStatusInfo).match({
          some: (rsi) => {
            dispatch(Actions.undockEditor(false));
            dispatch(Actions.updateTab(tab));
          },
          none: () => showErrorToastNow(I18n.t('cannot_change_tabs', { scope: 'shared.explore_grid.grid_column_header' }))
        });
      } else {
        dispatch(Actions.updateTab(tab));
      }
    },
    applyChanges: (fourfour: string, maybeChain: Option<BinaryTree<UnAnalyzedAst>>) => (tab: Option<Tab>) => {
      maybeChain.forEach(chain => {
        dispatch(Actions.compileAndRunAst(fourfour, chain, none, tab));
        dispatch(Actions.applyClicked(new Date()));
      });
    },
    discardChanges: (lastQueryText: Option<string>) => () => {
      lastQueryText.map((text) => {
        dispatch(Actions.setQueryText(text));
        dispatch(Actions.compileText(text));
      });
    }
  };
};

export type MergedProps<TStateProps, TDispatchProps> = ExternalProps & VisualContainerStateProps & VisualContainerProps & TStateProps & TDispatchProps;

export function mergeProps<TStateProps = unknown, TDispatchProps = unknown>(
  state: VisualContainerStateProps & TStateProps,
  disp: VisualContainerDispatchProps & TDispatchProps,
  ownProps: ExternalProps
): MergedProps<TStateProps, TDispatchProps> {
  const chain = getUnAnalyzedAst(state.query);
  const lastQueryText = querySuccess(state.query.queryResult).flatMap((qr) => qr.compiled.text);

  return {
    ...state,
    ...ownProps,
    ...disp,
    stageAST: disp.stageAST(chain),
    compileAST: disp.compileAST(chain),
    runAST: disp.runAST(state.fourfour, chain),
    updateTab: disp.updateTab(state.remoteStatusInfo, state.undocked),
    applyChanges: disp.applyChanges(state.fourfour, chain),
    discardChanges: disp.discardChanges(lastQueryText),
  };
}
