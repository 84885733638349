import React from 'react';
import { connect } from 'react-redux';
import SoQLDocs, { Match, defaultMatcher, ColumnLike, Matcher } from 'common/components/SoQLDocs';
import FunctionDoc from 'common/components/SoQLDocs/FunctionDoc';
import ColumnDoc from 'common/components/SoQLDocs/ColumnDoc';
import * as VisualContainer from './visualContainer';
import { FunSpec } from 'common/types/soql';
import { some, none, Option } from 'ts-option';

export const renderFunction = (name: string, fs: FunSpec[]) => (abbreviated: boolean) => {
  return <FunctionDoc impls={fs} name={name} abbreviated={abbreviated} />;
};

export const renderColumn = (column: ColumnLike) => (abbreviated: boolean) => {
  return <ColumnDoc column={column} abbreviated={abbreviated} />;
};


export class SoQLQueryDocs extends React.Component<VisualContainer.VisualContainerProps> {
  matcher: Option<Matcher> = none;

  constructor(props: VisualContainer.VisualContainerProps) {
    super(props);
    this.initMatcher(props);
  }

  componentWillReceiveProps = (nextProps: VisualContainer.VisualContainerProps) => {
    if (this.props.scope.length !== nextProps.scope.length) {
      this.initMatcher(nextProps);
    }
  };

  // this is a stateful initialization because the matcher is a closure which closes
  // over an expensive-ish operation which turns the function list into a map for quick
  // lookups so match can be called while the user types.
  // but this component can be initialized before we join the channel and get the scope
  // (ie: the scope may be empty) or it could be initialized when we already have the scope,
  // so we need to initialize the matcher if the scope changes (componentWillReceiveProps) as
  // well as in the constructor
  initMatcher = (props: VisualContainer.VisualContainerProps) => {
    if (props.scope.length > 0) {
      this.matcher = some(
        defaultMatcher(
          props.scope,
          [],
          renderFunction,
          renderColumn
        )
      );
    }
  };

  render() {
    const content = this.matcher.match({
      some: (matcher) => (
        <SoQLDocs
          selection={undefined}
          completer={matcher} />
      ),
      none: () => (
        <span className="spinnerdefault spinnerlarge" />
      )
    });


    return (
      <div className="grid-datasource-components scroll-container">
        {content}
      </div>
    );
  }
}

export default connect(
  VisualContainer.mapStateToProps,
  VisualContainer.mapDispatchToProps,
  VisualContainer.mergeProps
)(SoQLQueryDocs);
